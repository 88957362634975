<template>
  <Main center>
    <Status :heading="heading">
      <template v-slot:picture>
        <svg width="85" height="105" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M84.505 2.523a4.186 4.186 0 00-3.834-2.56H4.148c-1.1 0-2.155.436-2.932 1.213A4.138 4.138 0 000 4.103v96.624a4.14 4.14 0 001.216 2.927 4.157 4.157 0 002.932 1.213h76.526a4.16 4.16 0 002.932-1.214 4.145 4.145 0 001.216-2.926V4.103a4.06 4.06 0 00-.314-1.58h-.003zm-.607 98.204a3.221 3.221 0 01-.946 2.276 3.233 3.233 0 01-2.28.944H4.147a3.23 3.23 0 01-2.281-.943 3.219 3.219 0 01-.946-2.277V4.103a3.222 3.222 0 01.947-2.276 3.235 3.235 0 012.28-.945h76.526a3.247 3.247 0 012.995 2.026 3.185 3.185 0 01.232 1.195l-.003 96.624z"
            fill="#3F3D56"
          />
          <path
            d="M68.535 22.775h-23.05a1.846 1.846 0 01-1.843-1.84 1.839 1.839 0 011.844-1.841h23.05a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.535 32.437h-23.05a1.847 1.847 0 01-1.706-1.135 1.838 1.838 0 011.706-2.546h23.05a1.849 1.849 0 011.706 1.136 1.838 1.838 0 01-1 2.406c-.224.092-.464.14-.706.14z"
            fill="#3F3D56"
          />
          <path
            d="M33.659 37.94H16.814a2.08 2.08 0 01-1.466-.608 2.07 2.07 0 01-.609-1.463v-19.84a2.07 2.07 0 012.075-2.071h16.845a2.07 2.07 0 012.075 2.07v19.84a2.07 2.07 0 01-.609 1.464 2.08 2.08 0 01-1.466.608z"
            fill="#22C58B"
          />
          <path
            d="M68.375 52.223H16.282a1.846 1.846 0 01-1.844-1.841 1.839 1.839 0 011.844-1.84h52.093a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.375 61.885H16.282a1.846 1.846 0 01-1.844-1.84 1.839 1.839 0 011.844-1.84h52.093a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.376 71.547H16.282a1.846 1.846 0 01-1.842-1.84 1.839 1.839 0 011.842-1.84h52.093a1.849 1.849 0 011.706 1.135 1.838 1.838 0 01-1.706 2.545zM68.375 81.21H16.282a1.846 1.846 0 01-1.844-1.84 1.839 1.839 0 011.844-1.841h52.093a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.375 90.872H16.282a1.846 1.846 0 01-1.844-1.84 1.839 1.839 0 011.844-1.841h52.093a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.841z"
            fill="#CCC"
          />
          <path
            d="M49 93a5 5 0 100-10 5 5 0 000 10zM43.53 81a4.421 4.421 0 01-2.442-.737 4.547 4.547 0 01-1.654-1.974L29.51 55.512a6.155 6.155 0 01-.048-4.8 6.058 6.058 0 011.36-2.022 5.925 5.925 0 012.035-1.306 5.838 5.838 0 014.704.238 5.956 5.956 0 011.898 1.505 6.09 6.09 0 011.157 2.148l7.185 23.822c.206.682.252 1.404.135 2.107a4.609 4.609 0 01-.811 1.943 4.498 4.498 0 01-1.582 1.362A4.406 4.406 0 0143.53 81z"
            fill="#22C58B"
          />
        </svg>
      </template>

      <template v-if="version === `v3`" v-slot:details>
        <P>In order to complete your application, we'll need you to provide us with some additional information.</P>

        <P>Kafene is texting you a link now with next steps.</P>

        <P
          >Once you receive a final text message with our decision, please click the button below to continue your
          application.</P
        >
      </template>

      <template v-else v-slot:details>
        <P>{{ getLocal(language, 'info.p1') }}</P>

        <P>{{ getLocal(language, 'info.p2') }}</P>
      </template>

      <template v-if="version === `v3`" v-slot:cta>
        <Button fixed expanded large :disabled="loading" :loading="loading" @click="check"
          >Find out the Final Decision</Button
        >
      </template>

      <template v-else v-slot:cta>
        <Button fixed expanded large href="https://kafene.com/">{{ getLocal(language, 'home') }}</Button>
      </template>
    </Status>
  </Main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'

import Main from '../components/Main.vue'
import Status from '../components/Status.vue'
import Button from '../components/common/Button'
import P from '../components/common/P'

export default {
  name: 'MoreInfo',
  components: {
    Button,
    Main,
    Status,
    P
  },
  setup() {
    return {
      store: useStore(),
      loading: ref(false)
    }
  },
  computed: {
    version() {
      return this.store.getters['application/getVersion']
    },
    language() {
      return this.$store.getters.getLanguage
    },
    heading() {
      return this.version === `v3`
        ? this.getLocal(this.language, 'info.version3Header')
        : this.getLocal(this.language, 'info.header')
    }
  },
  methods: {
    getLocal,
    async check() {
      this.loading = true
      await this.store.dispatch('redirectOnStatus', () => (this.loading = false))
    }
  }
}
</script>
